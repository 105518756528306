import {defineStore, mapActions} from 'pinia';
import axios from "axios";
import {notificationStore} from "@/store/notificationStore";

export const authStore = defineStore({
    id: 'authStore',
    state: () => ({
        userInfo: null,
        managerProfileInfo: null,
        activeUserInfo: null,
        userActivated: true,
        acceptedCookie: true,
        approveOrgList: [],
        needVerifyUserModalState: false,

        // Auth modal
        loginModalState: false,

        // Sign up modal
        signUpModalState: false,

        // Reset password modal
        resetPasswordModalState: false,
        resetPasswordModalCloseCallback: null,

        // Change email modal
        changeEmailModalState: false,

        // Create org
        createOrgState: false,
        registerPartyInfoState: false,

        // Leave page confirm modal
        leavePageModal: false,
        hasUnsavedChanges: true,
        unsavedChangesCallback: null
    }),
    getters: {
        hasHeaderMessage() {
            return !this.userActivated || !this.acceptedCookie;
        },
        userLoggedIn() {
            return this.userInfo !== null;
        },
        userIsManager() {
            return this.managerProfileInfo !== null;
        }
    },
    actions: {
        ...mapActions(notificationStore, [
            'getUnreadNewsCountAction',
            'getCriticalEventCountAction',
            'cleanNotificationStore'
        ]),

        // Auth modal
        loginAction(formData) {
            const config = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' }};
            return axios.post('/api/login', formData, config);
        },
        logoutAction() {
            return axios.post('/api/logout')
                .then(() => {
                    this.userInfo = null;
                    this.managerProfileInfo = null;
                    this.userActivated = true;
                    this.cleanNotificationStore();
                })
                .catch(error => console.log(error));
        },
        authGetUserData() {
            return axios.get('/api/user/desktop')
                .then((res) => {
                    this.userInfo = res.data;
                    this.setActiveUserAction(res.data);
                    this.checkUserActive();
                })
                .catch(error => console.log(error));
        },
        getManagerProfile() {
            return axios.get('/api/manager/profile')
                .then((resp)=> {
                    this.managerProfileInfo = resp.data;
                })
                .catch(error => console.log(error));
        },
        getApprovedOrganizations() {
            axios.get('/api/user/desktop/organizations/approved')
                .then((res) => {
                    this.approveOrgList = res.data;
                })
                .catch(error => console.log(error));
        },
        checkUserActive() {
            axios.get('/api/user/desktop/pendingemail')
                .then((res) => {
                    this.userActivated = !res.data;
                })
                .catch(error => console.log(error));
        },
        setActiveUserAction(userInfo) {
            this.activeUserInfo = userInfo;
        },
        setCookieAcceptedAction() {
            localStorage.setItem('coockieTerms:accepted', true);
        },
        checkCookieAcceptedAction() {
            this.acceptedCookie = localStorage.getItem('coockieTerms:accepted', true) === 'true';
        },
        toggleLoginModalState() {
            this.loginModalState = !this.loginModalState;
        },

        // Sign up modal
        openSignUpModal() {
            this.signUpModalState = true;
        },
        closeSignUpModal() {
            this.signUpModalState = false;
        },
        signUpRequest(formData) {
            return axios.post('/api/registration/join', formData)
                .then(() => {
                    window.location.href='/registrationwizard';
                })
                .catch(error => console.log(error));
        },


        // Reset password modal
        openResetPasswordModal(closeCallback) {
            this.resetPasswordModalState = true;

            if (closeCallback) {
                this.resetPasswordModalCloseCallback = closeCallback;
            }
        },
        closeResetPasswordModal() {
            this.resetPasswordModalState = false;
            if (this.resetPasswordModalCloseCallback) {
                this.resetPasswordModalCloseCallback();
                this.resetPasswordModalCloseCallback = null;
            }
        },
        sendPasswordAction(email) {
            return axios.post(`/api/auth/sendpassword?email=${email}`)
                .then(() => {
                    this.resetPasswordModalCloseCallback = null;
                    this.closeResetPasswordModal();
                });
        },

        // Change email modal
        toggleChangeEmailModalState() {
            this.changeEmailModalState = !this.changeEmailModalState;
        },
        resendRegistrationEmail() {
            axios.post('/api/user/desktop/resendregistrationemail')
                .catch(error => console.log(error));
        },
        changeEmailRequest(newEmail) {
            return axios.post(`/api/user/desktop/updateprimaryemail?email=${newEmail}`)
                .then(() => {
                    this.toggleChangeEmailModalState();
                    this.authGetUserData();
                })
                .catch(error => console.log(error));
        },
        checkUserIsApproved() {
            let state = true;

            if (!this.userActivated) {
                this.toggleNeedVerifyUserModal();
                state = false;
            }

            return state;
        },
        toggleNeedVerifyUserModal() {
            return this.needVerifyUserModalState = !this.needVerifyUserModalState;
        },

        //Create org
        toggleCreateOrgModal() {
            this.createOrgState = !this.createOrgState;
        },
        toggleRegisterPartyInfoModal() {
            this.registerPartyInfoState = !this.registerPartyInfoState;
        },

        // Leave page confirm modal
        setUnsavedChanges(state, callback) {
            this.hasUnsavedChanges = state;

            if (callback) {
                this.unsavedChangesCallback = callback;
            }
        },
        toggleLeavePageModal() {
            this.leavePageModal = !this.leavePageModal;
        },
    }
});