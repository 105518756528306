<script>
import BaseIframe from "@/components/BaseIframe.vue";
export default {
  name: "BecomeManagerView",
  components: {
    BaseIframe
  },
  mounted() {
    if (screen.width < 800) {
      const mvp = document.getElementById('metaviewport');
      mvp.setAttribute('content','user-scalable=no,width=670');
    }
  }
}
</script>

<template>
  <Base-iframe
      :src="'/becomeManager?_f=true'"
      :show-header="false"
  />
</template>