import { defineStore } from 'pinia';
import axios from 'axios';
export const bankIdStore = defineStore({
    id: 'bankIdStore',
    state: () => ({
        bankIdModalState: false,
        bankIdStartToken: '',
        authState: false,
        successCallback: null,
        notAuthCallback: null,

    }),
    actions: {
        showBankIdModal() {
            this.bankIdModalState = true;
        },
        hideBankIdModal() {
            this.bankIdModalState = false;
        },
        requestNewAuthSession(callback) {
            return axios.get('/api/bankid/auth/request').then((res)=> {
                this.bankIdStartToken = res.data.autoStartToken;

                if (callback) {
                    callback();
                }
            })
        },
        checkBankIdAuthSession(successCallback, notAuthCallback, errorCallback) {

            if (successCallback) {
                this.successCallback = successCallback;
            }

            axios.get('/api/bankid/auth/request').then((res)=> {
                if (res.status === 200) {
                    this.bankIdStartToken = res.data.autoStartToken;

                    if (notAuthCallback) {
                        notAuthCallback();
                    } else {
                        this.showBankIdModal();
                    }
                }
            }).catch((error)=> {
                var status = error.response.status;

                if (status === 400) {
                    if (this.successCallback) {
                        this.successCallback();
                    }
                } else  {
                    if (errorCallback) {
                        errorCallback(error);
                    }
                }
            });



        }

    }
})