<script>
import { mapState, mapActions } from 'pinia'
import {authStore} from "@/store/authStore";
import {bankIdStore} from "@/store/bankIdStore";
import {baseLoaderStore} from "@/store/baseLoaderStore";
import {notificationStore} from "@/store/notificationStore";
import {successMessageStore} from "@/store/successMessageStore";
import BaseLoader from "@/components/BaseLoader.vue";
import BankIdModal from "@/components/BankId/BankIdModal.vue";
import BaseSuccessMessage from "@/components/BaseSuccessMessage.vue";
import PleaseVerifyUserModal from "@/components/Modals/PleaseVerifyUserModal.vue";
export default {
  name: 'App',
  components: {
    BaseLoader,
    BankIdModal,
    BaseSuccessMessage,
    PleaseVerifyUserModal
  },
  computed: {
    ...mapState(baseLoaderStore, ['loaderState']),
    ...mapState(authStore, ['userInfo']),
    ...mapState(bankIdStore, ['bankIdModalState']),
    ...mapState(successMessageStore, ['successMessageState']),
  },
  methods: {
    ...mapActions(authStore, ['checkCookieAcceptedAction', 'authGetUserData', 'getManagerProfile','getApprovedOrganizations']),
    ...mapActions(notificationStore, ['getUnreadNewsCountAction', 'getCriticalEventCountAction']),
    ...mapActions(baseLoaderStore, ['showBaseLoader', 'hideBaseLoader']),
    checkMainAppRouter(url) {
      let hasView = this.$router.options.routes.filter(route => {
        return route.framePath === url;
      }).length > 0;

      if (hasView) {
        this.$router.push(url);
      }

      return hasView;
    },
    handleFrameNavigation() {
      window.addEventListener("message",  (e) => {
        const url = e.data.contentFrameUrl;
        const showLoader = e.data.showLoader;
        const hideLoader = e.data.hideLoader;

        if (url) {
          this.$router.push(url.replace('&_f=true','').replace('?_f=true',''));
        }

        if (showLoader) {
          this.showBaseLoader();
        }

        if (hideLoader) {
          this.hideBaseLoader();
        }
      }, false);
    },
    setViewport() {
      const viewportWidth = 1024;

      if (screen.width < viewportWidth) {
        const mvp = document.getElementById('metaviewport');
        mvp.setAttribute('content','user-scalable=no,width=' + viewportWidth);
      }
    }
  },
  beforeMount() {
    this.checkCookieAcceptedAction();
  },
  mounted(){
    this.authGetUserData().then(()=> {
      this.getApprovedOrganizations();
      if (this.userInfo) {
        this.$i18n.locale = this.userInfo.lang;
      } else {
        this.$i18n.locale = 'se';
      }
      this.getManagerProfile();
      this.getUnreadNewsCountAction();
      this.getCriticalEventCountAction();
    });
    this.handleFrameNavigation();
    this.setViewport();
  },
  created () {
    document.title = "PeoplePolitics";
  }
}
</script>

<template>
  <link type="text/css" rel="stylesheet" href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700">
  <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.7.2/css/all.css" integrity="sha384-fnmOCqbTlWIlj8LyTjo7mOUStjsKC4pOpQbqyi7RrhN7udi9RwhKkMHpvLbHG9Sr" crossorigin="anonymous">
  <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">

  <div class="base-content-wrap">
    <RouterView :key="$route.path"/>
  </div>
  <base-loader :state="loaderState"/>
  <bank-id-modal v-if="bankIdModalState"/>
  <base-success-message v-if="successMessageState"/>
  <please-verify-user-modal/>
</template>

<style lang="scss">
@import "~@/assets/scss/base/reset";
@import "~@/assets/scss//base/variables";
@import "~@/assets/scss//base/helpers";
@import "~@/assets/scss//base/transition";

  html {
    height: auto !important;
    font: 14px/18px 'Open Sans',Arial,sans-serif !important;
  }

  .base-content-wrap {
    margin: 0 auto 0;
  }

  html,
  body,
  .base-content-wrap,
  #app {
    min-height: 100vh;
    height: 100%;
  }

  body {
    height: 100vh;
    position: relative;
  }

  //Tooltip
  .p-tooltip {
    border-radius: 2px;

    .p-tooltip-text {
      padding: 7px;
      color: #ffffff;
      background: #4f4f4f;
      font-size: 12px;
    }
    .p-tooltip-arrow {
      border-top-color: #4f4f4f !important;
      border-bottom-color: #4f4f4f !important;
    }
  }

  // Base dialog
  .p-dialog {
    border-radius: 5px !important;

    .p-dialog-header {
      display: flex;
      align-items: center;
      height: 50px;
      padding: 0 15px;
      background: #f2f2f2;
      border-radius: 5px 5px 0 0;

      .p-dialog-title {
        font-size: 16px;
        font-weight: 600;
        color: #7835a6;
      }

      .p-dialog-close-button {
        color: #7835a6;
        &:hover,
        &:focus-visible{
          border: transparent;
          color: #7835a6 !important;
          background: transparent !important;
          outline: none;
        }
      }
    }
    .p-dialog-content {
      padding: 10px 15px;
    }

    .p-dialog-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 50px;
      padding: 0 15px;
      background: #f2f2f2;
      border-radius: 0 0 5px 5px;

      .p-dialog-primary-btn {
        height: 32px;
        padding: 0 15px;
        background: #7130a0;
        font-family: 'Open Sans',Arial,sans-serif;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        color: #fff;
        border-radius: 4px;
        border: none;
        outline: none;
        cursor: pointer;
        &:hover {
          background: #672992;
        }

        &.disabled {
          background: #909090;
          cursor: default;
        }
      }

      .p-dialog-close-btn {
        font-family: 'Open Sans',Arial,sans-serif;
        font-size: 14px;
        font-weight: 600;
        border: none;
        outline: 0;
        text-transform: uppercase;
        cursor: pointer;
        color: #7130a0;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
</style>
